import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  drawer: {
    '@global': {
      '.ant-drawer-body': {
        height: '100%',
      },
    },
  },
});

export default useStyles;
