import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    backgroundImage:
      'url(https://storage.googleapis.com/download/storage/v1/b/nego-dev.appspot.com/o/products%2F1573529830420.jpg?generation=1573529830801638&alt=media)',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
});

export default useStyles;
