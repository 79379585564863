import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    '@global': {
      '.ant-spin-nested-loading': {
        height: '100%',
      },
      '.ant-spin-container': {
        height: '100%',
      },
      '.ant-spin': {
        color: '#1890ff',
        maxHeight: 'inherit !important',
      },
    },
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});

export default useStyles;
