import React from 'react';
import { Row, Col, Carousel } from 'antd';
import useStyles from './banner-styles';

const Banner = ({ banners }) => {
  const classes = useStyles();

  return (
    <Row>
      <Col span={16}>
        <Carousel autoplay>
          {banners.map((url) => {
            return (
              <div className={classes.leftBanner}>
                <div
                  className={classes.bannerImage}
                  style={{ 'background-image': `url(${url})` }}
                />
              </div>
            );
          })}
        </Carousel>
      </Col>
      <Col span={8}>
        <div className={classes.rightBanner}>
          <img
            alt="point and click"
            src="https://www.anglissonline.com.sg/wp-content/uploads/2019/04/Tag-image-2-01.jpg"
          />
        </div>
      </Col>
    </Row>
  );
};

export default Banner;
