import React from 'react';
import { Typography, Button, Tooltip } from 'antd';
import { useNegotiation } from '../../../hooks/useNegotiation';
import useStyles from './product-styles';

const { Paragraph } = Typography;
const Product = ({ id, name, description, image_url }) => {
  const classes = useStyles();
  const setNegotiaton = useNegotiation();

  const onClickEnqireButton = () => {
    setNegotiaton({ id, name, description, image_url });
  };
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img className={classes.image} alt="product" src={image_url} />
      </div>
      <div className={classes.productInfoContainer}>
        <Button className={classes.linkButton} type="link" block>
          {name}
        </Button>
        <Tooltip placement="top" title={description}>
          <Paragraph type="secondary" ellipsis>
            {description}
          </Paragraph>
        </Tooltip>
      </div>
      <div className={classes.actionContainer}>
        <Button className={classes.negoButton} type="primary" onClick={onClickEnqireButton}>
          Enquire
        </Button>
      </div>
    </div>
  );
};

export default Product;
