/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import { Spin, Icon } from 'antd';
import configs from '../../configs';
import useStyles from './nego-iframe-styles';

const NegoIframe = ({ id, name, product }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const refIframe = useRef(null);
  useEffect(() => {
    if (!refIframe) {
      return;
    }
    const data = {
      organisation_id: 'angliss',
      product_id: product ? product.id : '',
      customer_name: name,
      customer_email: id,
      text: 'Product Enquiry',
    };
    refIframe.current.contentWindow.postMessage(JSON.stringify(data), '*');
  }, [id, name, product, loading]);

  const params = qs.stringify({
    apiUrl: configs.SVC_GCP,
    apiKey: configs.API_KEY,
    identity: id,
  });
  const iframeUrl = `${configs.CHAT_IFRAME}?${params}`;

  return (
    <div className={classes.root}>
      <Spin
        tip="Loading chat..."
        spinning={loading}
        indicator={
          <Icon
            type="loading"
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        <iframe
          className={classes.iframe}
          title="nego"
          ref={refIframe}
          src={iframeUrl}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </Spin>
    </div>
  );
};

export default NegoIframe;
