import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts as selectorGetProducts } from '../redux/selectors';
import { getProducts as actionGetProducts } from '../redux/actions';

const useProducts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionGetProducts());
  }, [dispatch]);
  return useSelector(selectorGetProducts);
};

export default useProducts;
