import { fork } from 'redux-saga/effects';
import { sagas as requestSagas } from './sagas/request-sagas';
import { sagas as appSagas } from './app/sagas';
import { sagas as homeSagas } from './features/home/sagas';

export function* sagas() {
  yield fork(requestSagas);
  yield fork(appSagas);
  yield fork(homeSagas);
}
