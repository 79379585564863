import { useDispatch } from 'react-redux';
import { login as actionLogin } from '../../../app/redux/actions';

const useLogin = () => {
  const dispatch = useDispatch();
  return (values) => {
    dispatch(actionLogin(values));
  };
};

export default useLogin;
