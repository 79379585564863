import { createUseStyles } from 'react-jss';
import * as variables from '../../../../../styles/variables';

const useStyles = createUseStyles({
  root: {
    marginBottom: 40,
  },
  title: {
    fontFamily: 'Lobster',
    color: variables.primaryColor,
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 20,
  },
});

export default useStyles;
