import { TYPES } from '../constants';

export const initialState = {
  email: '',
  username: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        email: action.payload.email,
        username: action.payload.username,
      };
    default:
      return state;
  }
}
