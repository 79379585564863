import React from 'react';
import MainContent from './components/main-content';
import Layout from '../../components/layout';
import NegoButton from '../../components/nego-button';
import { useNegotiation, useNegotiationData } from './hooks/useNegotiation';
import useAuth from '../../app/hooks/useAuth';
import useStyles from './home-styles';

const Home = () => {
  const classes = useStyles();
  const negotiation = useNegotiationData();
  const setNegotiaton = useNegotiation();
  const { email, username } = useAuth();

  return (
    <Layout>
      <MainContent />
      <NegoButton
        className={classes.negoButton}
        email={email}
        username={username}
        negotiation={negotiation}
        setNegotiation={setNegotiaton}
      />
    </Layout>
  );
};

export default Home;
