import { createUseStyles } from 'react-jss';
import * as variables from '../../../../../styles/variables';

const useStyles = createUseStyles({
  root: {
    marginBottom: 20,
  },
  title: {
    fontFamily: 'Lobster',
    color: variables.primaryColor,
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 20,
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    width: '80%',
  },
});

export default useStyles;
