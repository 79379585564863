import React from 'react';
import Layout from '../../components/layout';
import LoginForm from './components/login-form';
import useLogin from './hooks/useLogin';

const Login = () => {
  const login = useLogin();
  const handleSubmit = (values) => {
    login(values);
  };

  return (
    <Layout>
      <LoginForm onSubmit={handleSubmit} />
    </Layout>
  );
};

export default Login;
