import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  leftBanner: {
    height: 390,
    background: 'white',
    overflow: 'hidden',
  },
  bannerImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
  },
  rightBanner: {
    height: 390,
    background: 'white',
    '& img': {
      width: '100%',
      maxHeight: 390,
    },
  },
});

export default useStyles;
