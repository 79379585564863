import { call, put, takeEvery } from 'redux-saga/effects';
import request from '../helpers/network/request';

export const requestError = (type, error) => ({
  type: `${type}_FAILURE`,
  payload: error.response.data,
});

export const requestSuccess = (type, response) => ({
  type: `${type}_SUCCESS`,
  payload: response.data,
});

export function* makeRequest({ type, meta: { request: data } }) {
  const response = yield call(request, data);
  const { status } = response;
  if (status >= 200 && status < 300) {
    yield put(requestSuccess(type, response));
  } else {
    yield put(requestError(type, response));
  }
}

export const sagas = function* () {
  yield takeEvery((action) => action.meta && action.meta.request, makeRequest);
};
