import axios from 'axios';

/**
 * Make a request to the backend
 * @param {string} method
 * @param {string} url
 * @param {*} headers
 */
const request = async ({ method, url, headers, ...params }) => {
  try {
    const response = await axios({
      method,
      url,
      headers,
      ...params,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export default request;
