import { createUseStyles } from 'react-jss';
import * as variables from '../../../../../styles/variables';

const useStyles = createUseStyles({
  root: {
    background: 'url(https://www.anglissonline.com.sg/wp-content/uploads/2018/11/ice-2.jpg)',
    padding: [80, 280],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    fontFamily: 'Lobster',
    color: variables.primaryColor,
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 20,
  },
});

export default useStyles;
