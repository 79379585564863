import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    '@global': {
      '.ant-layout-header': {
        height: 'initial',
        padding: 0,
        margin: 0,
      },
    },
  },
  chatEntry: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
});

export default useStyles;
