import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  negoButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1000,
  },
});

export default useStyles;
