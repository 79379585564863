import { createUseStyles } from 'react-jss';
import * as variables from '../../../../../styles/variables';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: 30,
  },
  productInfoContainer: {
    width: '100%',
    textAlign: 'center',
    color: '#222',
  },
  linkButton: {
    color: variables.secondaryColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  inputQuantity: {
    width: 120,
    textAlign: 'center',
    marginBottom: 12,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    maxWidth: '100%',
    width: 'auto',
    height: 250,
    margin: ['auto', 'auto'],
  },
  negoButton: {
    marginTop: 12,
  },
});

export default useStyles;
