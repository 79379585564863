import React from 'react';
import Banner from './banner/banner';
import ProductList from './products/product-list';
import PromotionList from './promotions/promotion-list';
import CategoryList from './categories/category-list';
import useStyles from './main-content-styles';
import useProducts from '../../hooks/useProducts';

const promotions = [
  'https://www.anglissonline.com.sg/wp-content/uploads/2020/02/MarApr20-promo-300x300.jpg',
  'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Promo-3.jpg',
];

const banners = [
  'https://www.anglissonline.com.sg/wp-content/uploads/2018/12/Main-Banners-01.jpg',
  'https://www.anglissonline.com.sg/wp-content/uploads/2018/12/Main-Banners-02.jpg',
  'https://www.anglissonline.com.sg/wp-content/uploads/2018/12/Main-Banners-03.jpg',
  'https://www.anglissonline.com.sg/wp-content/uploads/2018/12/Main-Banners-04.jpg',
];

const categories = [
  {
    name: 'Beef',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-01.jpg',
  },
  {
    name: 'Lamb/Mutton',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-02.jpg',
  },
  {
    name: 'Pork',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-03.jpg',
  },
  {
    name: 'Poultry',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-04.jpg',
  },
  {
    name: 'Seafood',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-05.jpg',
  },
  {
    name: 'Dairy/Pastry/Desserts',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-06.jpg',
  },
  {
    name: 'Delicatessen',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-07.jpg',
  },
  {
    name: 'Fruits/Vegetables',
    url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-08.jpg',
  },
  // { name: 'Fruits/Finger Food', url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-09.jpg' },
  // { name: 'Speciality', url: 'https://www.anglissonline.com.sg/wp-content/uploads/2018/11/Browse-by-Category-10.jpg' },
];

const MainContent = () => {
  const classes = useStyles();
  const products = useProducts();
  return (
    <div className={classes.root}>
      <Banner banners={banners} />
      <ProductList products={products} />
      <PromotionList promotions={promotions} />
      <CategoryList categories={categories} />
    </div>
  );
};

export default MainContent;
