import { createSelector } from 'reselect';

export const getRequestState = (state) => state.requestReducer || {};

export const getLoadingWithKey = (key) =>
  createSelector(getRequestState, (app) => (app.loading && app.loading[key]) || false);

export const getErrorWithKey = (key) =>
  createSelector(getRequestState, (app) => (app.error && app.error[key]) || '');

export const getAuthState = (state) => state.authReducer || {};

export const getIdentity = createSelector(getAuthState, ({ email, username }) => ({
  email,
  username,
}));
