import { createUseStyles } from 'react-jss';
import * as variables from '../../../styles/variables';

const useStyles = createUseStyles({
  usefulLink: {
    color: variables.primaryColor,
  },
  usefulButton: {
    color: variables.secondaryColor,
  },
});

export default useStyles;
