import { put, takeLatest } from 'redux-saga/effects';
import { TYPES } from './constants';
import { parseProducts as actionParseProducts } from './redux/actions';

export function* parseProducts(action) {
  yield put(actionParseProducts(action.payload));
}

// eslint-disable-next-line func-names
export const sagas = function* () {
  yield takeLatest(TYPES.GET_PRODUCTS_SUCCESS, parseProducts);
};
