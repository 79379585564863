import * as constants from '../constants';

export const getProducts = () => ({
  type: constants.TYPES.GET_PRODUCTS,
  meta: {
    request: {
      url: constants.APIS.GET_PRODUCTS,
      method: 'GET',
    },
  },
});

export const parseProducts = (data) => ({
  type: constants.TYPES.PARSE_PRODUCTS,
  payload: data,
});

export const setNegotiation = (data) => ({
  type: constants.TYPES.SET_NEGOTIATION,
  payload: data,
});
