import * as constants from '../constants';

const login = ({ email, username }) => ({
  type: constants.TYPES.LOGIN,
  payload: {
    email,
    username,
  },
});

export { login };
