import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 400,
    backgroundImage:
      'url(https://www.anglissonline.com.sg/wp-content/uploads/2018/11/squid-1-1.jpg)',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  form: {
    width: '100%',
    margin: [0, 150],
    '@global': {
      '.ant-typography': {
        color: '#bba06b',
      },
    },
  },
});

export default useStyles;
