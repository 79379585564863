export const initialState = {
  loading: {},
  error: {},
};

export default function appReducer(state = initialState, action) {
  const { type, meta, payload } = action;
  if (meta && meta.request) {
    return {
      ...state,
      error: {
        ...state.error,
        [type]: '',
      },
      loading: {
        ...state.loading,
        [type]: true,
      },
    };
  }
  const matches = /(.*)_(SUCCESS|FAILURE)/.exec(type);
  if (!matches) return state;
  const [, actionType] = matches;
  return {
    ...state,
    error: {
      ...state.error,
      [actionType]: payload.message ? payload.message : '',
    },
    loading: {
      ...state.loading,
      [actionType]: false,
    },
  };
}
