import { useSelector, useDispatch } from 'react-redux';
import { getNegotiation as selectorGetNegotiation } from '../redux/selectors';
import { setNegotiation as actionSetNegotiation } from '../redux/actions';

export const useNegotiation = (product) => {
  const dispatch = useDispatch();
  return (product) => {
    dispatch(actionSetNegotiation(product));
  };
};

export const useNegotiationData = () => {
  return useSelector(selectorGetNegotiation);
};
