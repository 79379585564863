import { TYPES } from '../constants';

export const initialState = {
  products: [],
  negotiation: undefined,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.PARSE_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case TYPES.GET_PRODUCTS_FAILURE:
      return {
        ...state,
      };
    case TYPES.SET_NEGOTIATION:
      return {
        ...state,
        negotiation: action.payload,
      };
    default:
      return state;
  }
}
