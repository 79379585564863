import { call, takeLatest } from 'redux-saga/effects';
import { TYPES } from './constants';
import history from '../history';
import * as logRocket from '../utils/logrocket';

export function* login(action) {
  const { state } = history.location;
  let redirect = '/home';
  if (state && state.referrer) {
    redirect = state.referrer;
  }
  const { payload } = action;
  logRocket.identify(payload.email, payload);
  yield call(history.push, redirect);
}

// eslint-disable-next-line func-names
export const sagas = function* () {
  yield takeLatest(TYPES.LOGIN, login);
};
